import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import readingLogLayout from '../components/readingLog.module.scss'
import { graphql } from 'gatsby'
//import { documentToReactComponents } from '@contentful/rich-text-react-renderer'


export const query = graphql`
    query {
        allContentfulReadingLog(
        sort: {
        fields: authors,
        order: ASC
        }
        ){
            edges {	
                node{
                    title
                    authors
                    tags
                    webLink
                    contentful_id
        }
        }
    }
    }

`

const ReadingLog = (props) => {
    const logs = props.data.allContentfulReadingLog.edges;
    console.log(logs)

    return(
        <Layout>
            <div className="mainContentWidth">
            <Head title="Readging Log" />
            
            <h2 className={readingLogLayout.refTitle}> Reference Material</h2><div>
            <p> I spend a lot of time reading, mostly non-fiction books. The topics range from art, philosophy, pedagogy to digital culture, language and music. There is a focus on more specialised subjects such as graphic design, typography, new media and code. As of late, I've devled more into psychology and neuroscience with a keen eye towards learning more about how we perceive. In line with this I'm an avid observer of the rise of what I call the <i>Artifical Intelligentsia Conundrum. </i></p>
            </div><br></br>
            <div className='breakLine'></div><br></br>
            <ol className= {readingLogLayout.gridSimple01}>
                {
                    logs
                    .map(( { node }) => {
                        const title = node.title;
                        const author = node.authors;
                        const webUrl = node.webLink;
                        const tags = node.tags;

                        const id = node.contentful_id;
                        
                            return(
                                <div className={readingLogLayout.referenceLog} key= {id}>
                                    <p><a href={webUrl}>{title}</a></p>
                                    <p> { author }</p>
                                    { tags ? (
                                    <p className={readingLogLayout.noteTag}> tags: { tags.join(", ") }</p>
                                    ) : ("")
                                    }
                                
                                </div>
                            ) 
                    
                    })
                }
            </ol>
            </div>

        </Layout>
    )
}

export default ReadingLog